<template>
  <div class="pageV">
    <div class="header">
      <van-nav-bar
        :title="$t('AddBankCard')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="infoV">
      <div class="infoItemV">
        <span class="infoTv1">{{ $t("RealName") }}:</span>
        <div class="infoTv2">
          <input v-model="form.real_name" :placeholder="$t('PleaseEnterRealName')" />
        </div>
      </div>

      <div class="infoItemV">
        <span class="infoTv1">{{ $t("Telephone") }}:</span>
        <div class="infoTv2">
          <input v-model="form.telephone" type="number" :placeholder="$t('PleaseEnterPhoneNumber')" />
        </div>
      </div>

      <div class="infoItemV">
        <span class="infoTv1">{{ $t("BankName") }}:</span>
        <div class="infoTv2">
          <input v-model="form.bank_name" :placeholder="$t('PleaseEnterBankName')" />
        </div>
      </div>

      <div class="infoItemV">
        <span class="infoTv1">{{ $t("BankAccount") }}</span>
        <div class="infoTv2">
          <input v-model="form.number" type="number" :placeholder="$t('PleaseEnterBankAccount')" />
        </div>
      </div>

      <div v-if="isIfsc" class="infoItemV">
        <span class="infoTv1">{{ $t("IFSC") }}</span>
        <div class="infoTv2">
          <input v-model="form.ifsc" :placeholder="$t('PleaseEnterIFSC')" />
        </div>
      </div>

      <van-button
        class="confirmTv"
        :loading="loading"
        :loading-text="$t('jia-zai-zhong')"
        @click="addCard"
      >
        {{ $t("Save") }}
      </van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      isIfsc: false,
      form: {
        real_name: "",
        telephone: "",
        bank_name: "",
        number: "",
        ifsc: "",
      },
    };
  },
  created() {
    this.getMoney();
  },
  methods: {
    async getMoney() {
      if (this.lang == "en") {
        this.isIfsc = true;
      } else if (this.lang == "ru") {
        this.isIfsc = false;
      } else if (this.lang == "zh") {
        this.isIfsc = false;
      } else if (this.lang == "fr") {
        this.isIfsc = false;
      } else if (this.lang == "it") {
        this.isIfsc = false;
      } else if (this.lang == "es") {
        this.isIfsc = false;
      }
    },
    async addCard() {
      if (this.form.real_name === "") {
        this.$toast.fail(this.$t("PleaseEnterRealName"));
        return false;
      }
      if (this.form.telephone === "") {
        this.$toast.fail(this.$t("PleaseEnterPhoneNumber"));
        return false;
      }
      if (this.form.bank_name === "") {
        this.$toast.fail(this.$t("PleaseEnterBankName"));
        return false;
      }
      if (this.form.number === "") {
        this.$toast.fail(this.$t("PleaseEnterBankAccount"));
        return false;
      }
      if (this.isIfsc&&this.form.ifsc === "") {
        this.$toast.fail(this.$t("PleaseEnterIFSC"));
        return false;
      }
      this.loading = true;
      const { data } = await this.$http.post("/home/user/userCard",this.form);
      if (data) {
        if (data.code === 200) {
          this.$toast.fail(this.$t("操作成功"));
          this.$router.go(-1)
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.pageV {
  min-height: 100vh;
  background-color: #f7f7f7;
}
.infoV {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoItemV {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 0.5px solid #e6e6e6;

  .infoTv1 {
    flex: 0.3;
    font-size: 0.87rem;
    color: #333;
    text-align: left;
  }

  .infoTv2 {
    flex: 0.7;

    input {
      width: 100%;
      border: none;
      font-size: 0.87rem;
      color: #333;
    }
  }
}

.confirmTv {
  width: 90%;
  border-radius: 0.5rem;
  margin-top: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.12rem;
  color: #fff;
  height: 2.75rem;
  background: #0a51a1;
}
</style>
